import { getUserToken, setUserToken } from '@/utils/tools'

const state = {
  userId: localStorage.getItem('userID') || '',
  role: [],
  token: getUserToken()
}

const mutations = {
  setRole(state, payload) {
    state.token = payload
  },
  setUserId(state, payload) {
    state.token = payload
  },
  setToken(state, payload) {
    state.token = payload.token
    setUserToken(state.token, payload.expires)
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
import * as axios from 'axios'

import store from '@/store'
import router from '@/router'

export const axiosRequestLists = []

const baseURL = process.env.VUE_APP_BATH_URL
const CancelToken = axios.CancelToken

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? baseURL : '/',
  headers: {
    'Content-type': 'application/json'
  },
  time: 20000,
  maxContentLength: 40000
})

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = store.state.user.token
    config.cancelToken = new CancelToken(cancel => {
      axiosRequestLists.push(cancel)
    })

    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = 'Bearer ' + token // 请求头加上token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
instance.interceptors.response.use(
  res => {
    return res.data
  },
  err => {
    if (err.response?.status === 401) {
      window.localStorage.removeItem('menu')
      window.localStorage.removeItem('userID')
      window.localStorage.removeItem('token')
      router.replace({ path: '/login' })
      return
    }

    if (axios.isCancel(err)) {
      return new Promise(() => {})
    } else {
      return Promise.reject(err)
    }
  }
)

export default instance
import service from './request'

import { Spin, Message } from 'view-design'
import { loadingSpin } from '@/utils/tools'
import dayjs from 'dayjs'

async function axiosServer({
  url,
  method = 'get',
  data,
  params,
  responseType,
  headers,
  random = false,
  loading = true
}) {
  loading && loadingSpin()
  if (random) {
    params = { ...params, t: dayjs().unix() }
  }
  try {
    const result = await service({
      url,
      method,
      data,
      params: {
        ...params
      },
      headers,
      responseType
    })
    if (result.code !== 200 && responseType !== 'blob') {
      Message.error({
        content: result.message,
        duration: 2
      })
    }
    return result
  } catch (error) {
    console.log(error)
    Message.error({
      content: error,
      duration: 2
    })
  } finally {
    Spin.hide()
  }
}

export default axiosServer

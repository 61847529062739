import { deepCopy } from '@/utils/tools'
import { fetchMenus } from '@/api/system/'

export const defaultList = [
  {
    name: '文献库',
    value: 'Literature',
    url: '/literature'
  },
  {
    name: '我的图书馆',
    value: 'Library',
    url: '/library'
  },
  {
    name: '热搜分析',
    value: 'Analyze',
    url: '/analyze'
  },
  {
    name: '系统管理',
    value: 'System',
    url: '/system'
  },
  {
    name: '个人中心',
    value: 'Personal',
    url: '/personal'
  }
]

// const URL_MAP = {
//   文献库: '/literature',
//   我的图书馆: '/library',
//   热搜分析: '/analyze',
//   系统管理: '/system',
//   个人中心: '/personal',
//   会议速递: '/meeting',
//   智库: '/zhiku'
// }

const URL_MAP = new Map([
  ['文献库', { url: '/literature', name: 'Literature', sotr: 1 }],
  ['我的图书馆', { url: '/library', name: 'Library', sotr: 4 }],
  ['热搜分析', { url: '/analyze', name: 'Analyze', sotr: 5 }],
  ['系统管理', { url: '/system', name: 'System', sotr: 7 }],
  ['个人中心', { url: '/personal', name: 'Personal', sotr: 6 }],
  ['会议速递', { url: '/meeting', name: 'Meeting', sotr: 2 }],
  ['智库', { url: '/zhiku', name: 'Zhiku', sotr: 3 }]
])

export function getMenuLists(permission, temp = []) {
  permission.forEach(item => {
    const target = defaultList.filter(v => item === v.name)
    temp = [...temp, ...target]
  })
  return temp
}

const state = {
  activeName: '',
  menuList: []
}

const mutations = {
  setActiveName(state, payload) {
    state.activeName = payload
  },
  setMenuLists(state, payload) {
    fetchMenus()
      .then(res => {
        const arr = deepCopy(payload)
        const menuArr = res.data
          .filter(item => item.state === 1)
          .map(item => {
            return {
              name: item.name,
              value: URL_MAP.get(item.name).name,
              url: URL_MAP.get(item.name).url
            }
          })
        const data = [...arr, ...menuArr].reduce((acc, current) => {
          const duplicate = acc.find(item => item.name === current.name)
          if (!duplicate) {
            acc.push(current)
          }
          return acc
        }, []).map(item => {
          return {
            ...item,
            sort: URL_MAP.get(item.name).sotr
          }
        })
        state.menuList = data.sort((a, b) => a.sort - b.sort)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

<template>
  <div id="app">
    <!-- <BackTop :height="100" :right="16"></BackTop> -->
    <router-view />
  </div>
</template>

<script>
// import { BackTop } from 'view-design'
export default {
  created() {
    // this.$store.dispatch('zhiku/getBiomarkers')
    // this.$store.dispatch('zhiku/getDiseaseList')
    // this.$store.dispatch('zhiku/getDrugList')
    // this.$store.dispatch('zhiku/getMedicalList')
    // this.$store.dispatch('zhiku/getResearchList')
    // this.$store.dispatch('zhiku/getBiomarkersList', { label_type: 2 })
    // this.$store.dispatch('zhiku/getReagentList', { label_type: 6 })
    // this.$store.dispatch('zhiku/getInstrumentList', { label_type: 7 })
    // 同步错误
    window.addEventListener('error', function(e) {
      console.log(e)
    })
    // promise错误
    window.addEventListener('unhandledrejection', function(event) {
      console.log(`Promise rejected: ${event.reason}`)
    })
  }
}
</script>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  // overflow: auto !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-size: 192px;
}

#app {
  width: 100%;
  // height: 100%;
  // background-color: #f5f7f9;
  // height: 100%;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
.ivu-table-row-hover {
  cursor: pointer !important;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>

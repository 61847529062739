import {
  getBiomarkers,
  getDiseaseList,
  getDrugList,
  getMedicalList,
  getResearchList,
  // getBiomarkersList,
  // getReagentList,
  // getInstrumentList,
  getZhiKuTreeTags,
  getResearchTypeList
} from '@/api/zhiku'
// import { deepCopy } from 'utils/tools'

const createNewTrees = arr => {
  if (arr.length) {
    const newArr = []
    // eslint-disable-next-line array-callback-return
    arr.map(item => {
      if (item.children && item.children.length > 0) {
        newArr.push({
          ...item,
          label: item.name,
          children: createNewTrees(item.children)
        })
      } else {
        newArr.push({
          id: item.id,
          label: item.name
        })
      }
    })
    return newArr
  } else {
    return []
  }
}
const state = {
  biomarkers: [],
  diseaseList: [],
  drugList: [],
  medicalList: [],
  researchList: [],
  biomarkersList: [],
  reagentList: [],
  instrumentList: [],
  researchTypeList: []
}

const mutations = {
  setBiomarkers(state, payload) {
    state.biomarkers = payload
  },
  setDiseaseList(state, payload) {
    state.diseaseList = payload
  },
  setDrugList(state, payload) {
    state.drugList = payload
  },
  setMedicalList(state, payload) {
    state.medicalList = payload
  },
  setResearchList(state, payload) {
    state.researchList = payload
  },
  setBiomarkersList(state, payload) {
    state.biomarkersList = payload
  },
  setReagentList(state, payload) {
    state.reagentList = payload
  },
  setInstrumentList(state, payload) {
    state.instrumentList = payload
  },
  setResearchTypeList(state, payload) {
    state.researchTypeList = payload
  }
}

const actions = {
  getBiomarkers({ commit }, params) {
    getBiomarkers(params)
      .then(res => {
        commit('setBiomarkers', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  },
  getDiseaseList({ commit }, params) {
    getDiseaseList(params)
      .then(res => {
        commit('setDiseaseList', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  },
  getDrugList({ commit }, params) {
    getDrugList(params)
      .then(res => {
        commit('setDrugList', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  },
  getMedicalList({ commit }, params) {
    getMedicalList(params)
      .then(res => {
        commit('setMedicalList', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  },
  getResearchList({ commit }, params) {
    getResearchList(params)
      .then(res => {
        commit('setResearchList', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  },
  getBiomarkersList({ commit }, params) {
    getZhiKuTreeTags(params)
      .then(res => {
        commit(
          'setBiomarkersList',
          res.data.map(item => ({ ...item, label: item.name }))
        )
      })
      .catch(err => {
        console.log(err)
      })
  },
  getReagentList({ commit }, params) {
    getZhiKuTreeTags(params)
      .then(res => {
        commit(
          'setReagentList',
          res.data.map(item => ({ ...item, label: item.name }))
        )
      })
      .catch(err => {
        console.log(err)
      })
  },
  getInstrumentList({ commit }, params) {
    getZhiKuTreeTags(params)
      .then(res => {
        console.log(res, 'instrumentList')
        commit(
          'setInstrumentList',
          res.data.map(item => ({ ...item, label: item.name }))
        )
      })
      .catch(err => {
        console.log(err)
      })
  },
  getTypeList({ commit }, params) {
    getResearchTypeList(params)
      .then(res => {
        commit('setResearchTypeList', createNewTrees(res.data))
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

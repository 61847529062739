import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'view-design/dist/styles/iview.css'
import './static/css/index.less'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { Message } from 'view-design'

import skeleton from 'vue-skeleton-component'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(skeleton)
Vue.component('treeselect', Treeselect)
Vue.use(VueViewer)

Vue.config.productionTip = false

Message.config({
  duration: 3
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

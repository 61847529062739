import Vue from 'vue'
import Vuex from 'vuex'
import header from './header'
import user from './user'
import literature from './literature'
import zhiku from './zhiku'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    header,
    user,
    literature,
    zhiku
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import store from '@/store'
import { getMenuLists } from '@/store/header'

import { axiosRequestLists } from '../server/request'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    redirect: '/literature'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('views/login')
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('views/layout'),
    children: [
      {
        path: 'literature',
        meta: {
          title: 'Literature',
          auth: true
        },
        name: 'Literature',
        component: () => import('views/literature')
      },
      {
        path: 'literature/lists',
        name: 'Lists',
        meta: {
          title: 'Literature',
          auth: true
        },
        component: () => import('views/literature/lists')
      },
      {
        path: 'literature/detail',
        name: 'DashboardDetail',
        meta: {
          title: 'Literature',
          auth: true
        },
        component: () => import('views/literature/detail')
      },
      {
        path: 'library',
        name: 'Library',
        component: () => import('views/library')
      },
      {
        path: 'analyze',
        name: 'Analyze',
        component: () => import('views/analyze')
      },
      {
        path: 'system',
        name: 'System',
        // meta: {
        //   title: 'Literature',
        //   auth: true
        // },
        component: () => import('views/system')
      },
      {
        path: 'personal',
        name: 'Personal',
        // meta: {
        //   title: 'Literature',
        //   auth: true
        // },
        component: () => import('views/profile')
      },
      {
        path: 'meeting',
        name: 'Meeting',
        component: () => import('views/meeting')
      },
      {
        path: 'zhiku',
        name: 'Zhiku',
        component: () => import('views/zhiku')
      },
      {
        path: 'zhiku/lists',
        name: 'ZhikuLists',
        meta: {
          title: 'Zhiku',
          auth: true
        },
        component: () => import('views/zhiku/list/index')
      },
      {
        path: 'zhiku/dashboards',
        name: 'ZhikuDashboards',
        meta: {
          title: 'Zhiku',
          auth: true
        },
        component: () => import('views/zhiku/list/dashboard-list')
      },
      {
        path: 'zhiku/literature',
        name: 'ZhikuLiterature',
        meta: {
          title: 'Zhiku',
          auth: true
        },
        component: () => import('views/zhiku/detail/literature')
      },
      {
        path: 'zhiku/guide',
        name: 'ZhikuGuide',
        meta: {
          title: 'Zhiku',
          auth: true
        },
        component: () => import('views/zhiku/detail/guide')
      }
    ]
  }
]

const router = new VueRouter({
  routes: constantRoutes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  } // 路由切换无法滚动
})

router.beforeEach((to, from, next) => {
  // 刷新头部菜单
  const menu = localStorage.getItem('menu')?.split(',') || []

  if ((!menu || !menu.length) && to.path !== '/login') {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  if (axiosRequestLists.length) {
    axiosRequestLists.forEach(c => c())
    axiosRequestLists.length = 0
  }

  if (to.path === '/login') return next()
  const token = Cookies.get('JWT')
  const auth = to.matched.some(matcher => matcher.meta.auth)
  if (auth) {
    if (token) {
      const temp = getMenuLists(menu)
      store.commit('header/setMenuLists', temp)
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

export default router

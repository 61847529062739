import { BaseServe } from '@/server'

// 账号管理数据预览
const fetchSystemPreview = (params) => {
  return BaseServe({
    url: '/users/overall/',
    params: {
      ...params
    }
  })
}

// 账号管理注册趋势
const fetchSystemRegisterTrend = (params) => {
  return BaseServe({
    url: '/users/dashboard/register/',
    params: {
      ...params
    }
  })
}

// 公司分布
const fetchSystemCompanyDistribute = (params) => {
  return BaseServe({
    url: '/users/dashboard/company_type/',
    params: {
      ...params
    }
  })
}

// 职称分布
const fetchSystemPositionDistribute = (params) => {
  return BaseServe({
    url: '/users/dashboard/position/',
    params: {
      ...params
    }
  })
}

// 医院等级
const fetchSystemHospitalLevelDistribute = (params) => {
  return BaseServe({
    url: '/users/dashboard/hospital_level/',
    params: {
      ...params
    }
  })
}

// 科室注册
const fetchSystemDepartment = (params) => {
  return BaseServe({
    url: '/users/dashboard/department/',
    params: {
      ...params
    }
  })
}

// 省份分布
const fetchSystemProvinceDistribute = (params) => {
  return BaseServe({
    url: '/users/dashboard/province/',
    params: {
      ...params
    }
  })
}

// 公共导出接口
const fetchSystemExport = (params, url) => {
  return BaseServe({
    url: `/users/dashboard/${url}/export/`,
    params: {
      ...params
    },
    responseType: {
      responseType: 'blob'
    }
  })
}
// 用户列表
const fetchSystemUsers = (params) => {
  return BaseServe({
    url: '/users/accounts/',
    params: {
      ...params
    }
  })
}

// 批量修改用户角色
const fetchSystemPutUserRole = (params) => {
  return BaseServe({
    url: '/users/accounts/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 获取地区
const fetchSystemArea = (params) => {
  return BaseServe({
    url: '/users/get_group_filter_list/',
    params: {
      ...params
    }
  })
}

// 获取角色
const fetchSystemRoles = (params) => {
  return BaseServe({
    url: '/users/role_list/',
    params: {
      ...params
    }
  })
}

// 医院等级
const fetchSystemHospitalLevel = (params) => {
  return BaseServe({
    url: '/users/get_group_filter_list/',
    params: {
      ...params
    }
  })
}

// 单位类型
const fetchSystemCompanyType = (params) => {
  return BaseServe({
    url: '/users/company_type_list/',
    params: {
      ...params
    }
  })
}
// 角色列表
const fetchSystemRoleList = (params) => {
  return BaseServe({
    url: '/users/roles/',
    params: {
      ...params
    }
  })
}
// 角色修改
const fetchSystemPutRole = (id, data) => {
  return BaseServe({
    url: `/users/role/${id}`,
    method: 'put',
    data
  })
}
// 角色添加
const fetchSystemAddRole = (data) => {
  return BaseServe({
    url: '/users/roles/',
    method: 'post',
    data
  })
}
// 用户反馈
const fetchSystemFeedBack = (params) => {
  return BaseServe({
    url: '/users/feedback/',
    params
  })
}

const fetchSystemFeedBackExport = (params) => {
  return BaseServe({
    url: '/users/feedback_export/',
    params
  })
}

const putPwd = (data, id) => {
  return BaseServe({
    url: `/users/account/${id}/change_password/`,
    method: 'put',
    data
  })
}

const fetchMenus = (params) => {
  return BaseServe({
    url: '/users/permission_modules/',
    params
  })
}

// 菜单修改
const fetchSystemPutMenu = (id, data) => {
  return BaseServe({
    url: `/users/permission_module/${id}/`,
    method: 'put',
    data
  })
}

export {
  fetchSystemPreview,
  fetchSystemRegisterTrend,
  fetchSystemUsers,
  fetchSystemPutUserRole,
  fetchSystemArea,
  fetchSystemCompanyDistribute,
  fetchSystemPositionDistribute,
  fetchSystemHospitalLevelDistribute,
  fetchSystemDepartment,
  fetchSystemProvinceDistribute,
  fetchSystemRoles,
  fetchSystemExport,
  fetchSystemHospitalLevel,
  fetchSystemCompanyType,
  fetchSystemRoleList,
  fetchSystemPutRole,
  fetchSystemAddRole,
  fetchSystemFeedBack,
  fetchSystemFeedBackExport,
  putPwd,
  fetchMenus,
  fetchSystemPutMenu
}
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { Spin, Icon, Message } from 'view-design'
import { config } from '@/server/config'
import querystring from 'querystring'
import cloneDeep from 'lodash/cloneDeep'
import { downloadFile } from '@/api/meeting'

function loadingSpin() {
  Spin.show({
    render: h => {
      return h('div', [
        h(Icon, {
          class: 'demo-spin-icon-load',
          props: {
            type: 'ios-loading',
            size: 18
          }
        }),
        h('div', 'Loading')
      ])
    }
  })
}

const debounce = (fn, ms = 500) => {
  let timeoutId
  return function(...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

const vueDebounce = (fn, wait = 500) => {
  let timer = null
  return function(...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      this[fn].apply(this, args)
    }, wait)
  }
}

function throttle(fn, wait = 500) {
  let timer = null
  return function(...args) {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, args)
      }, wait)
    }
  }
}

// 树结构
function setLabelsOption(option) {
  const temp = {}
  Object.keys(option).forEach(item => {
    temp[item] = setSelect(option[item].children)
  })
  return temp
}

// 标签选中
function setSelect(target) {
  const labels = []
  if (!target.length) return []
  target
    .filter(item => item.isSelect)
    .forEach(val => labels.push({ id: val.id || undefined, label: val.label || undefined }))
  return labels
}

// 设置过期时间
// const cookieExpires = 90

function setUserToken(token, cookieExpires = 1) {
  Cookies.set('JWT', token, { expires: cookieExpires })
}

function getUserToken() {
  return Cookies.get('JWT') || ''
}

// 处理筛选结构
function setFilter(source) {
  return source.map(item => ({
    ...item,
    label: item.name,
    value: item.name,
    isSelect: item.isSelect || false
  }))
}

function setFilterFromResponse(data, target) {
  Object.keys(data)
    .filter(item => item !== 'publish_year' && item !== 'impact_factor')
    .forEach(item => {
      target[item].children = setFilter(data[item])
    })
}

function setFilterFromResponseLibrary(data, target) {
  Object.keys(data)
    .filter(item => item === 'article_type' || item === 'language' || item === 'field')
    .forEach(item => {
      target[item].children = setFilter(data[item])
    })
}

function setSliderFromRes(data, target) {
  Object.keys(data)
    .filter(item => item === 'publish_year' || item === 'impact_factor')
    .forEach(item => {
      target[item].range = cloneDeep(data[item])
      target[item].valueRange = cloneDeep(data[item])
    })
}

// 序列化
function setStringify(data) {
  const temp = {}
  const save = []
  const formulas = []
  const filtersLabels = ['language', 'article_type', 'publish_year', 'impact_factor']
  Object.keys(data).forEach(item => {
    temp[item] = data[item].length ? data[item].map(val => val.id || val.label).join(',') : undefined
    data[item].forEach(val => save.push(val.label))
    if (!filtersLabels.includes(item)) {
      data[item].forEach(val => formulas.push(val.label))
    }
  })
  return {
    temp,
    save,
    formulas
  }
}

// labels children 是否为空
function isChildrenEmpty() {
  return !!Array.from(arguments).filter(item => item?.children.length).length
}

function setFileName(name) {
  const formatDate = dayjs(new Date().valueOf()).format('YYYYMMDD')
  return !name ? formatDate : `${name}-${formatDate}`
}

// 图片文件导出
function setFilesExport(blob, name, type = 'png') {
  saveAs(blob, `${setFileName(name)}.${type}`)
}

// pdf文件下载
function saveFilesPdf(blob, name, type = 'pdf') {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', `${setFileName(name)}.${type}`)
  document.body.appendChild(link)
  link.click()
}

// saveAs保存pdf
function saveAsPdf(blob, name) {
  try {
    const exportContent = '\uFEFF'
    const data = new Blob([exportContent, blob], { type: 'application/pdf;charset=utf-8' })
    saveAs(data, `${setFileName(name)}.pdf`)
  } catch (error) {
    Message.error({
      content: '导出失败~',
      duration: 2
    })
  }
}

// 处理表格数据
function setTableData(data) {
  return data.map(item => ({
    label: item.name,
    value: item.value
  }))
}

// 处理饼图数据
function setPieData(data) {
  return data.map(item => ({
    ...item,
    per: item.freq
  }))
}

// 拼接高级检索字符串
function setAdvancedStr(data, str = '') {
  data.forEach((item, index) => {
    if (index === 0) {
      str += `${item.label}=${item.search}`
    } else {
      str += `,${item.logic},${item.label}=${item.search}`
    }
  })
  return str
}

// 高级搜索逆向
function setStrToAdvanced(data) {
  const logic = data.filter(item => item.toLowerCase() === 'and' || item.toLowerCase() === 'or')
  return data
    .filter(item => item.indexOf('=') >= 0)
    .map(item => {
      const temp = item.split('=')
      return {
        label: temp[0],
        search: temp[1]
      }
    })
    .map((item, index) => {
      if (index > 0) {
        return {
          ...item,
          logic: logic[index - 1]
        }
      } else {
        return item
      }
    })
}

// 处理订阅请求字段
function setSubscribeFetch(data) {
  const temp = {}
  Object.keys(data).forEach(item => {
    temp[item] = data[item].length ? data[item].map(val => val.id).join(',') : undefined
  })
  return temp
}

// 拼接订阅的content
function subscribeContent(data, disease) {
  let str = ''
  data.forEach((item, index) => {
    if (index === 0) {
      str += `${item}`
    } else if (index === 1 && disease) {
      str += `-${item}`
    } else {
      str += `\tand\t${item}`
    }
  })
  return str
}

function savePdfWithOpen(data, url, target, user = false) {
  const temp = {
    // is_export: '1',
    ...target
  }
  Object.keys(data).forEach(item => {
    if (data[item]) {
      temp[item] = data[item]
    }
  })
  const str = querystring.stringify({
    ...temp
  })
  const host = process.env.NODE_ENV === 'production' ? config.pro : config.pro
  user ? window.open(`${host}/${url}/?${str}`) : window.open(`${host}/api/${url}/?${str}`)
}

function limitInputValue(content) {
  let len = 0
  for (var i = 0; i < content.length; i++) {
    // 正则表达式判断中文
    if (/[\u4e00-\u9fa5]/.test(content[i])) {
      len += 2
    } else {
      len++
    }
  }
  return len
}

// 获取指定格式时间
function getAssignFormatTime(date, layout = 'YYYY-MM-DD', type = 'default') {
  if (type === 'default') {
    return dayjs().format(layout)
  }
  if (type === 'date') {
    return dayjs()
      .subtract(date, type)
      .format(layout)
  }
  return dayjs()
    .subtract(date, type)
    .add(1, 'day')
    .format(layout)
}

function getSelectList(data) {
  return data.map(v => ({ label: v, value: v }))
}

function setSelected(data) {
  return data.filter(i => !!i.name).map(v => ({ label: v.name, value: v.value }))
}

function setUserCommonExport(option, url) {
  savePdfWithOpen(option, url, {}, true)
}

function typeOf(obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

// deepCopy
function deepCopy(data) {
  const t = typeOf(data)
  let o

  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }

  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (t === 'object') {
    for (const i in data) {
      o[i] = deepCopy(data[i])
    }
  }
  return o
}
// 删除对象中为空的字段
function deleteObjEmptyData(obj) {
  const newObj = deepCopy(obj)
  for (const item in obj) {
    const t = typeOf(newObj[item])
    if (t === 'array') {
      if (!newObj[item].length) {
        delete newObj[item]
      } else {
        newObj[item] = newObj[item].join(' ')
      }
    } else {
      if (newObj[item] === '' || newObj[item] === null || newObj[item] === undefined) {
        delete newObj[item]
      }
    }
  }
  return newObj
}

// 文件流预览
function filePreview(url) {
  if (!url) return
  const fielMIMEMap = new Map([
    ['png', 'image/png'],
    ['jpg', 'image/jpg'],
    ['jpeg', 'image/jpeg'],
    ['gif', 'image/gif'],
    ['bmp', 'image/bmp']
  ])
  const fileName = decodeURIComponent(url)
    .split('/')
    .pop()
  const fileType = fileName.split('.').pop()
  downloadFile({ file: url }).then(res => {
    if (res.type === 'application/pdf') {
      const pdfFile = window.URL.createObjectURL(res)
      window.open(pdfFile)
      return
    }
    if (res.type === 'application/octet-stream' && fielMIMEMap.get(fileType)) {
      const file = window.URL.createObjectURL(new Blob([res], { type: fielMIMEMap.get(fileType) }))
      window.open(file)
      return
    }
    saveAs(res, fileName)
  })
}

export {
  cloneDeep,
  debounce,
  throttle,
  setLabelsOption,
  setUserToken,
  getUserToken,
  setFilter,
  setStringify,
  setFilterFromResponse,
  setFilterFromResponseLibrary,
  isChildrenEmpty,
  setFilesExport,
  loadingSpin,
  saveFilesPdf,
  setTableData,
  setPieData,
  setAdvancedStr,
  setSubscribeFetch,
  setStrToAdvanced,
  saveAsPdf,
  subscribeContent,
  savePdfWithOpen,
  limitInputValue,
  setSliderFromRes,
  vueDebounce,
  getAssignFormatTime,
  getSelectList,
  setSelected,
  setUserCommonExport,
  deepCopy,
  deleteObjEmptyData,
  filePreview
}

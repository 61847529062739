const state = {
  listFlag: true
}

const mutations = {
  setFlag(state, payload) {
    state.listFlag = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
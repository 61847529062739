import { BaseServe } from '@/server'

// 获取会议列表
export const fetchMeetingList = (params) => {
  return BaseServe({
    url: '/meeting/meetings/',
    params
  })
}

// 获取会议标签类型列表
export const fetchMeetingLabelType = (params) => {
  return BaseServe({
    url: '/meeting/label_types/',
    params
  })
}

// 获取会议标签列表
export const fetchMeetingLabel = (params) => {
  return BaseServe({
    url: '/meeting/labels/',
    params,
    loading: false
  })
}

// 新增标签
export const addMeetingLabel = (data) => {
  return BaseServe({
    url: '/meeting/labels/',
    method: 'post',
    data
  })
}

// 删除标签
export const deleteMeetingLabel = (id) => {
  return BaseServe({
    url: `/meeting/label/${id}/`,
    method: 'delete'
  })
}

// 修改标签
export const updateMeetingLabel = (id, data) => {
  return BaseServe({
    url: `/meeting/label/${id}/`,
    method: 'put',
    data
  })
}

// 新增会议
export const addMeeting = (data) => {
  return BaseServe({
    url: '/meeting/meetings/',
    method: 'post',
    data
  })
}

// 获取会议详情
export const fetchMeetingDetail = (id, params) => {
  return BaseServe({
    url: `/meeting/meeting/${id}/`,
    params
  })
}

// 修改会议
export const updateMeeting = (id, data) => {
  return BaseServe({
    url: `/meeting/meeting/${id}/`,
    method: 'put',
    data
  })
}

// 删除会议
export const deleteMeeting = (id) => {
  return BaseServe({
    url: `/meeting/meeting/${id}/`,
    method: 'delete'
  })
}

// 获取领域标签列表
export const fetchMeetingField = (params) => {
  return BaseServe({
    url: '/meeting/meetings/field_labels/',
    params
  })
}

// 新增领域标签
export const addMeetingField = (data) => {
  return BaseServe({
    url: '/meeting/field_labels/',
    method: 'post',
    data
  })
}

// 获取会议标签列表
export const fetchMeetingLabelList = (params) => {
  return BaseServe({
    url: '/meeting/meetings/meeting_labels/',
    params
  })
}

// 获取生物标志物列表
export const fetchMeetingBiomarker = (params) => {
  return BaseServe({
    url: '/meeting/meetings/bio_labels/',
    params
  })
}

// 新增生物标志物列表
export const addMeetingBiomarker = (data) => {
  return BaseServe({
    url: '/meeting/bio_labels/',
    method: 'post',
    data
  })
}

// 新增会议标签
export const addMeetingLabelList = (data) => {
  return BaseServe({
    url: '/meeting/meeting_labels/',
    method: 'post',
    data,
    loading: false
  })
}

// 文件上传
export const uploadFile = (data) => {
  return BaseServe({
    url: '/meeting/files/',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 文件下载
export const downloadFile = (data) => {
  return BaseServe({
    url: '/meeting/files/download/',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 批量删除会议
export const deleteMeetingList = (data) => {
  return BaseServe({
    url: '/meeting/meetings/delete/',
    method: 'post',
    data
  })
}
import { BaseServe } from '@/server'

// 获取主题列表
export const getObjectList = (params) => {
  return BaseServe({
    url: '/mind_lib/biomarkers/',
    params,
    random: true
  })
}
export const addObject = (data) => {
  return BaseServe({
    url: '/mind_lib/biomarkers/',
    method: 'post',
    data,
    random: true
  })
}
export const editObject = (id, data) => {
  return BaseServe({
    url: `/mind_lib/biomarker/${id}/`,
    method: 'put',
    data,
    random: true
  })
}
export const deleteObject = (id, data) => {
  return BaseServe({
    url: `/mind_lib/biomarker/${id}/`,
    method: 'delete',
    data,
    random: true
  })
}
// 获取标签类型列表
export const getTagTypeList = (params) => {
  return BaseServe({
    url: '/mind_lib/label_types/',
    params,
    random: true
  })
}

// 获取标签列表
export const getTagList = (params) => {
  return BaseServe({
    url: '/mind_lib/labels/',
    params,
    random: true
  })
}

// 标签详情
export const getTagDetail = (id) => {
  return BaseServe({
    url: `/mind_lib/label/${id}/`,
    random: true
  })
}

// 新增标签
export const addTag = (data) => {
  return BaseServe({
    url: '/mind_lib/labels/',
    method: 'post',
    data,
    random: true
  })
}

// 修改标签
export const editTag = (id, data) => {
  return BaseServe({
    url: `/mind_lib/label/${id}/`,
    method: 'put',
    data,
    random: true
  })
}

// 删除标签
export const deleteTag = (id) => {
  return BaseServe({
    url: `/mind_lib/label/${id}/`,
    method: 'delete',
    random: true
  })
}

// 获取文献列表
export const getLiteratureList = (params) => {
  return BaseServe({
    url: '/mind_lib/manage/articles/',
    params,
    random: true
  })
}

// 获取主题生物标志物
export const getBiomarkers = (params) => {
  return BaseServe({
    url: '/mind_lib/biomarker_list/',
    params,
    random: true
  })
}

// 获取相关疾病列表
export const getDiseaseList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/diseases/',
    params,
    random: true
  })
}

// 获取相关药物列表
export const getDrugList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/treatments/',
    params,
    random: true
  })
}

// 获取医学方向列表
export const getMedicalList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/meds/',
    params,
    random: true
  })
}

// 获取研究人群列表
export const getResearchList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/populations/',
    params,
    random: true
  })
}

// 获取生物标志物检测列表
export const getBiomarkersList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/bios/',
    params,
    random: true
  })
}

// 获取试剂品牌列表
export const getReagentList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/reagents/',
    params,
    random: true
  })
}

// 获取仪器厂家列表
export const getInstrumentList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/instruments/',
    params,
    random: true
  })
}

// 获取相关疾病列表
export const getDiseaseListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/diseases/',
    params,
    random: true
  })
}

// 获取相关药物列表
export const getDrugListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/treatments/',
    params,
    random: true
  })
}

// 获取医学方向列表
export const getMedicalListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/meds/',
    params,
    random: true
  })
}

// 获取研究人群列表
export const getResearchListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/populations/',
    params,
    random: true
  })
}

// 获取生物标志物检测列表
export const getBiomarkersListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/bios/',
    params,
    random: true
  })
}

// 获取试剂品牌列表
export const getReagentListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/reagents/',
    params,
    random: true
  })
}

// 获取仪器厂家列表
export const getInstrumentListArticle = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/instruments/',
    params,
    random: true
  })
}

// 新增文献
export const addLiterature = (data) => {
  return BaseServe({
    url: '/mind_lib/manage/articles/',
    method: 'post',
    data,
    random: true
  })
}

// 获取文献详情
export const getLiteratureDetail = (id) => {
  return BaseServe({
    url: `/mind_lib/manage/article/${id}/`,
    random: true
  })
}

// 修改文献
export const editLiterature = (id, data) => {
  return BaseServe({
    url: `/mind_lib/manage/article/${id}/`,
    method: 'put',
    data,
    random: true
  })
}

// 删除文献
export const deleteLiterature = (id) => {
  return BaseServe({
    url: `/mind_lib/manage/article/${id}/`,
    method: 'delete',
    random: true
  })
}

// 获取智库文献列表
export const getZhiKuLiteratureList = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库文献详情
export const getZhiKuLiteratureDetail = (id) => {
  return BaseServe({
    url: `/mind_lib/article/${id}/`,
    method: 'get',
    random: true
  })
}
// 获取智库标签图谱
export const getZhiKuLabelChart = (params) => {
  return BaseServe({
    url: '/mind_lib/tag_graph/',
    method: 'get',
    params,
    random: true
  })
}

// 获取检索式列表
export const getZhiKuRetrievalList = (params) => {
  return BaseServe({
    url: '/mind_lib/retrievals/',
    method: 'get',
    params,
    random: true
  })
}
export const addZhiKuRetrieval = (data) => {
  return BaseServe({
    url: '/mind_lib/retrievals/',
    method: 'post',
    data,
    random: true
  })
}
export const getZhiKuRetrievalDetail = (id) => {
  return BaseServe({
    url: `/mind_lib/retrieval/${id}/`,
    method: 'get',
    random: true
  })
}
export const deleteZhiKuRetrieval = (id) => {
  return BaseServe({
    url: `/mind_lib/retrieval/${id}/`,
    method: 'delete',
    random: true
  })
}
// 获取智库大洲统计
export const getZhiKuDashboardContinent = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/continent/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库国家统计
export const getZhiKuDashboardCountry = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/country/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库省份统计
export const getZhiKuDashboardProvince = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/province/',
    method: 'get',
    params,
    random: true
  })
}

// 获取智库发表趋势
export const getZhiKuDashboardPublish = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/publish_year/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库生物标志物
export const getZhiKuDashboardBiomarker = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/biomarker/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库通讯作者排名
export const getZhiKuDashboardAuthor = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/author/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库机构排行
export const getZhiKuDashboardInstitution = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/institution/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库仪器厂家
export const getZhiKuDashboardInstrument = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/instrument/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库试剂品牌
export const getZhiKuDashboardReagent = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/reagent/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库治疗
export const getZhiKuDashboardTreatments = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/treatments/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库相关疾病
export const getZhiKuDashboardDiseases = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/diseases/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库医学方向
export const getZhiKuDashboardMedicals = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/medicals/',
    method: 'get',
    params,
    random: true
  })
}
// 获取智库研究人群
export const getZhiKuDashboardPopulations = (params) => {
  return BaseServe({
    url: '/mind_lib/dashboard/populations/',
    method: 'get',
    params,
    random: true
  })
}
// PDF导出
export const getZhiKuLiteratureListPDF = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/export_pdf/',
    method: 'get',
    params,
    responseType: 'blob',
    random: true
  })
}
// PPT导出
export const getZhiKuLiteratureListPPT = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/export_pptx/',
    method: 'get',
    params,
    responseType: 'blob',
    random: true
  })
}
// EXCEL导出
export const getZhiKuLiteratureListEXCEL = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/export_excel/',
    method: 'get',
    params,
    responseType: 'blob',
    random: true
  })
}
// 获取树级标签平铺
export const getZhiKuTreeTags = (params) => {
  return BaseServe({
    url: '/mind_lib/select_labels/',
    method: 'get',
    params,
    random: true
  })
}
// 智库文献发布
export const publishZhiKuLiterature = (id, data) => {
  return BaseServe({
    url: `/mind_lib/manage/article/${id}/activate/`,
    method: 'put',
    data,
    random: true
  })
}
// 智库文献批量删除
export const deleteZhiKuLiteratureList = (data) => {
  return BaseServe({
    url: '/mind_lib/manage/article/delete_batch/',
    method: 'post',
    data
  })
}
// doi重复检测
export const checkDoi = (data) => {
  return BaseServe({
    url: '/mind_lib/check_doi/',
    method: 'post',
    data,
    loading: false
  })
}
// 获取大洲列表
export const getContinentList = (params) => {
  return BaseServe({
    url: '/mind_lib/continents/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 获取国家列表
export const getCountryList = (params) => {
  return BaseServe({
    url: '/mind_lib/countries/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 获取省份列表
export const getProvinceList = (params) => {
  return BaseServe({
    url: '/mind_lib/provinces/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 主题管理logo上传
export const uploadLogo = (data) => {
  return BaseServe({
    url: '/mind_lib/files/',
    method: 'post',
    data,
    loading: false
  })
}
// 获取研究类型列表
export const getResearchTypeList = (params) => {
  return BaseServe({
    url: '/mind_lib/label/research_types/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 研究类型筛选
export const getResearchTypeFilter = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/label/research_types/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 获取文献类型列表
export const getLiteratureTypeList = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/article_type/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 获取智库发表年份
export const getPublishYearList = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/publish_year/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}
// 获取智库影响因子
export const getImpactFactorList = (params) => {
  return BaseServe({
    url: '/mind_lib/articles/impact_factor/',
    method: 'get',
    params,
    loading: false,
    random: true
  })
}